import React, { FC } from 'react';
import Icon from 'common/IconCustom';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import { ArticleSettings } from '@shared/types/umbraco/compositions/';
import './ShareButtons.scss';
import { parseBoolean } from 'utils/parseBoolean';
import { isBrowser } from 'utils/browser';

const defaultConfig = {
  facebook2: {
    component: FacebookShareButton,
  },
  facebook: {
    component: FacebookShareButton,
  },
  twitter: {
    component: TwitterShareButton,
  },
  email: {
    component: EmailShareButton,
  },
};

const ShareButtons: FC<ArticleSettings.IShareButtons> = ({
  shareButtons,
  shareTitle = '',
  config,
}) => (
  <div className="share-buttons">
    {shareTitle ? <p className="share-buttons__text">{shareTitle}</p> : null}
    <ul className="share-buttons__links">
      {shareButtons.map(({ properties: { isShow, ariaLabel, variant } }) => {
        const shareLinkType = variant?.[0]?.toLowerCase();
        if (!defaultConfig?.[shareLinkType]) return null;
        const buttonConfig = config
          ? {
              ...defaultConfig?.[shareLinkType],
              ...config?.[shareLinkType],
            }
          : defaultConfig?.[shareLinkType];
        const { component, ...restConfig } = buttonConfig;
        const Element = component;
        const url = isBrowser() ? window.location.href : '';

        return !!Element && parseBoolean(isShow) ? (
          <li key={shareLinkType}>
            <Element
              aria-label={ariaLabel}
              url={url}
              data-testid={`${shareLinkType}-btn`}
              {...restConfig}
            >
              <Icon icon={shareLinkType} />
            </Element>
          </li>
        ) : null;
      })}
    </ul>
  </div>
);

export default ShareButtons;
